<header class="header-area">
    <div class="top-header">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-8">
                    <ul class="top-header-contact-info">
                        <li>
                            <i class='bx bx-phone-call'></i>
                            <span>ติดต่อสอบถาม</span>
                            <a href="tel:502464674">02-806-6088</a>
                        </li>
                        <li>
                            <i class='bx bx-envelope'></i>
                            <span>อีเมล</span>
                            <!-- <a href="mailto:hello@ednuv.com">support@epit.co.th</a> -->
                        </li>
                    </ul>
                </div>

                <div class="col-lg-4">
                    <div class="top-header-btn">
                        <a class="navbar-brand" routerLink="/"><img src="{{logoTiTle2}}" alt="{{logoTiTle2}}"
                                style="width: 80px;"></a>
                        <!-- <a class="navbar-brand" routerLink="/"><img src="{{logoTiTle2}}" alt="{{logoTiTle2}}"></a> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="navbar-area" ngStickyNav stickyClass="sticky-box-shadow" ngStickyNav>
        <div class="container">
            <nav class="navbar navbar-expand-lg navbar-light bg-light" [class.active]="classApplied">
                <a routerLink="/SignIn" class="default-btn" style="margin-right: 10px;"><i
                        class='bx bx-log-in icon-arrow before'></i><span class="label">ลงชื่อเข้าใช้</span><i
                        class="bx bx-log-in icon-arrow after"></i></a>

                <button class="navbar-toggler" type="button" (click)="toggleClass()">
                    <span class="burger-menu">
                        <span class="top-bar"></span>
                        <span class="middle-bar"></span>
                        <span class="bottom-bar"></span>
                    </span>
                </button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav">
                        <li class="nav-item"><a href="javascript:void(0)" routerLink="/" class="nav-link">หน้าหลัก</a>
                        </li>
                        <li class="nav-item"><a href="javascript:void(0)" routerLink="/about-page"
                                class="nav-link">เกี่ยวกับ</a>
                        </li>
                        <li class="nav-item"><a href="javascript:void(0)" routerLink="/news-page"
                                class="nav-link">ข่าวสาร</a>
                        </li>
                        <li class="nav-item"><a href="javascript:void(0)" routerLink="/all-courses-page"
                                class="nav-link">หลักสูตร</a>
                        </li>
                        <li class="nav-item"><a href="javascript:void(0)" routerLink="/questionandanswer-page"
                                class="nav-link">ถาม-ตอบ</a>
                        </li>
                        <li class="nav-item"><a href="javascript:void(0)" routerLink="/manual-page"
                                class="nav-link">การใช้งาน</a>
                        </li>
                        <li class="nav-item"><a href="javascript:void(0)" routerLink="/contacts-page"
                                class="nav-link">ติดต่อเรา</a>
                        </li>
                    </ul>
                </div>
                <div class="others-option">
                    <div class="dropdown language-switcher d-inline-block" [class.active]="classApplied2">
                        <button class="dropdown-toggle" type="button" (click)="toggleClass2()">
                            <img src="assets/img/thai-flag.jpg" class="shadow" alt="image">
                            <span>Thai<i class='bx bx-chevron-down'></i></span>
                        </button>
                        <div class="dropdown-menu">
                            <a href="#" class="dropdown-item d-flex align-items-center">
                                <img src="assets/img/us-flag.jpg" class="shadow-sm" alt="flag">
                                <span>English</span>
                            </a>
                        </div>
                    </div>
                    <a routerLink="/cart" class="cart-wrapper-btn d-inline-block">
                        <i class='bx bx-cart-alt'></i>
                        <span>01</span>
                    </a>
                    <div class="search-box d-inline-block">
                        <i class='bx bx-search' (click)="toggleClass3()"></i>
                    </div>
                </div>
            </nav>
        </div>
    </div>
</header>

<div class="search-overlay" [class.active]="classApplied3">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-close" (click)="toggleClass3()">
                <span class="search-overlay-close-line"></span>
                <span class="search-overlay-close-line"></span>
            </div>
            <div class="search-overlay-form">
                <form>
                    <input type="text" class="input-search" placeholder="ค้นหา...">
                    <button type="submit"><i class='bx bx-search-alt'></i></button>
                </form>
            </div>
        </div>
    </div>
</div>
