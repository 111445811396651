<footer class="footer-area">
    <div class="container" style="margin-bottom: -50px;margin-top: -30px;">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-3">
                <div class="logo" style="margin-top: -30px;margin-left: -70px;">
                    <a routerLink="/" class="d-inline-block" style="text-align: center;"><img src="{{logoTiTle2}}"
                            alt="{{logoTiTle2}}" style="width:55%;">
                        <p style="color: white;font-size: 20px;">
                            {{langAll.ministryofargiculater | translate}}</p>
                    </a>
                    <!-- <a routerLink="/" class="d-inline-block"><img src="{{logoTiTle2}}" alt="{{logoTiTle2}}"></a> -->
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-3">
                <div class="single-footer-widget mb-30">
                    <h3 style="font-family: sarabunlight;">{{langAll.contactus | translate}}</h3>
                    <ul class="contact-us-link">
                        <li>
                            <i class='bx bx-map'></i>
                            <a target="_blank">{{addressData}}
                                <div *ngIf="addressData == null || addressData == '' || addressData == undefined">
                                    <strong style="color: white;">* {{langAll.nodata | translate}}</strong>
                                </div>
                            </a>
                        </li>
                        <li>
                            <i class='bx bx-phone-call'></i>
                            <a href="tel:{{telData}}">{{telData}}
                                <div *ngIf="telData == null || telData == '' || telData == undefined">
                                    <strong style="color: white;">* {{langAll.nodata | translate}}</strong>
                                </div>
                            </a>
                        </li>
                        <li style="margin-bottom: 10px;">
                            <i class='bx bx-envelope'></i>
                            <a href="mailto:{{emailData}}">{{emailData}}
                                <div *ngIf="emailData == null || emailData == '' || emailData == undefined">
                                    <strong style="color: white;">* {{langAll.nodata | translate}}</strong>
                                </div>
                            </a>
                        </li>
                    </ul>
                    <!-- <ul class="social-link">
                        <li *ngIf="facebook != null">
                            <a href="{{facebook}}" data-toggle="tooltip" title="{{facebook}}"
                                class="d-block" target="_blank"><i class='bx bxl-facebook'></i>
                            </a>
                        </li>
                        <li *ngIf="line != null"><a data-toggle="tooltip" title="{{line}}" class="d-block"
                                target="_blank"><img src="assets/img/logo-line.png" style="padding-bottom: 20px;"></a>
                        </li>
                        <li *ngIf="instagram != null"><a data-toggle="tooltip" title="{{instagram}}" class="d-block"
                                target="_blank"><i class='bx bxl-instagram'></i></a></li>
                    </ul> -->
                    <div class="contact-us-link">
                        <div class="dropdown">
                            <button class="dropbtn"><i class='bx bxl-facebook'></i></button>
                            <div class="dropdown-content" *ngIf="facebook != null">
                                <a target="_blank" href="{{item}}" *ngFor="let item of facebook">
                                    <span>{{item}}</span>
                                </a>
                            </div>

                        </div>
                        <div class="dropdown">
                            <button class="dropbtn"><img src="assets/img/line-white.png"></button>
                            <div class="dropdown-content" *ngIf="line != null">
                                <a target="_blank" href="{{item}}" *ngFor="let item of line">
                                    <span>{{item}}</span>
                                </a>
                            </div>
                        </div>
                        <div class="dropdown">
                            <button class="dropbtn"><i class='bx bxl-instagram'></i></button>
                            <div class="dropdown-content" *ngIf="instagram != null">
                                <a target="_blank" href="{{item}}"
                                    *ngFor="let item of instagram"><span>{{item}}</span></a>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="col-lg-3 col-md-3 col-sm-3">
                <div class="single-footer-widget mb-30">
                    <h3 style="font-family: sarabunlight;">{{langAll.problemreport | translate}}</h3>
                    <div class="newsletter-box">
                        <!-- <p>ติดตามและรับข่าวสารจากเราก่อนใคร</p> -->
                        <form class="newsletter-form">
                            <!-- <label>อีเมล:</label>
                            <input type="email" class="input-newsletter" placeholder="ใส่อีเมลเพื่อรับข่าวสาร" name="EMAIL"> -->
                            <div type="button" (click)="onClickReportproblem()" class="default-btn">
                                <i class="bx bxs-bug icon-arrow
                                    before"></i><span class="label">
                                    {{langAll.reportyourproblem | translate}}</span><i class="bx bxs-bug icon-arrow
                                    after"></i>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-3">
                <div class="single-footer-widget mb-30">
                    <h3 style="font-family: sarabunlight;">{{langAll.websitevisitors|translate}}</h3>
                    <ul class="contact-us-link">
                        <li>
                            <i class='bx bx-user'></i>
                            <p style="color: white;" *ngIf="visitor!=undefined">{{langAll.total|translate}} :
                                {{visitor.countsAll}}</p>
                        </li>
                        <li>
                            <i class='bx bx-time'></i>
                            <p style="color: white;" *ngIf="visitor!=undefined">{{langAll.today|translate}} :
                                {{visitor.countsNow}}</p>
                        </li>
                        <li>
                            <i class='bx bx-calendar-event'></i>
                            <p style="color: white;" *ngIf="visitor!=undefined">{{langAll.yesterday|translate}} :
                                {{visitor.countsYesterday}}
                            </p>
                        </li>
                        <li>
                            <i class='bx bx-calendar'></i>
                            <p style="color: white;" *ngIf="visitor!=undefined">{{langAll.thismonth|translate}} :
                                {{visitor.countsMonth}}</p>
                        </li>

                    </ul>
                    <!-- <ul class="social-link">
                        <li *ngIf="facebook != null"><a href="{{facebook}}" data-toggle="tooltip" title="{{facebook}}"
                                class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li *ngIf="line != null"><a data-toggle="tooltip" title="{{line}}" class="d-block"
                                target="_blank"><img src="assets/img/logo-line.png" style="padding-bottom: 20px;"></a>
                        </li>
                        <li *ngIf="instagram != null"><a data-toggle="tooltip" title="{{instagram}}" class="d-block"
                                target="_blank"><i class='bx bxl-instagram'></i></a></li>
                    </ul> -->
                </div>
            </div>
        </div>
    </div>
    <div class="footer-bottom-area">
        <div class="container">
            <div class="logo">
                <!-- <a routerLink="/" class="d-inline-block"><img src="{{logoTiTle2}}" alt="{{logoTiTle2}}"></a> -->
            </div>
            <p><i class='bx bx-copyright'></i>2023 <a target="_blank">OPSMOAC Version 3.1</a> Designed By <a
                    href="https://www.epit2.com/" target="_blank">EP & IT Solution Co.,Ltd.</a> | All rights reserved.
            </p>
        </div>
    </div>
</footer>

<ngx-scrolltop></ngx-scrolltop>